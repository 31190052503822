import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const captureBaseline = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "CaptureBaseline", {
        offset: ["number", (value: any) => {
            const errors: ValidationError[] = []
            if (value < 0) {
                errors.push(new ValidationError(`offset "${value}" must be greater than or equal to 0`))
            }
            return errors
        }, { optional: true }],
    })

    return errors
}
