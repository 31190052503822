import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const configureIntensityChart = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "ConfigureIntensityChart", {
        referenceChannel: ["string", (value: any) => {
            const errors: ValidationError[] = []
            if (!configuration.channelConfiguration.some((channel) => channel.name === value)) {
                errors.push(new ValidationError(`referenceChannel "${value}" does not exist`))
            }
            return errors
        }, { optional: true }]
    })

    return errors
}
